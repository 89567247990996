@import url("https://fonts.cdnfonts.com/css/clash-display");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing:border-box
}

*,*:before,*:after{
  box-sizing:inherit
}

body {
  overflow: auto;
  margin: 0;
}
